import React from 'react'

const Adventure = (props) => (
  <svg width={95} height={24} viewBox="0 0 95 24" {...props}>
    <title>{'Adventure'}</title>
    <g fill="none" fillRule="evenodd">
      <text fontFamily="Lobster-Regular, Lobster" fontSize={14} fill="#7453A7">
        <tspan x={20.424} y={18}>
          {'adventure'}
        </tspan>
      </text>
      <path d="M2 17V3h86" stroke="#7453A7" strokeDasharray="4,2" />
      <circle fill="#7453A7" cx={2} cy={22} r={2} />
      <path fill="#7453A7" d="M89 1.768l1-1 4 4-1 1z" />
      <path fill="#7453A7" d="M93 .768l1 1-4 4-1-1z" />
    </g>
  </svg>
)

export default Adventure
