import React from 'react'

const Foodie = (props) => (
  <svg width={95} height={24} viewBox="0 0 95 24" {...props}>
    <title>{'Foodie'}</title>
    <g fill="#47B7CC" fillRule="evenodd">
      <text fontFamily="Lobster-Regular, Lobster" fontSize={15}>
        <tspan x={12.227} y={17}>
          {'foodie'}
        </tspan>
      </text>
      <path d="M4.941 7.008c0 .217.156.397.362.434l.08.007a.441.441 0 00.433-.361l.008-.08-.001-.098.066-3.469A.45.45 0 016.339 3c.247 0 .451.193.465.44L7 6.91v1.334c0 .976-.791 1.767-1.767 1.767h-.027l.565 10.056a.884.884 0 01-.833.932h-.024L4.038 21a.884.884 0 01-.884-.884l.001-.05.564-10.057-.097-.004A1.767 1.767 0 012 8.244V6.91l.196-3.47A.467.467 0 012.662 3a.45.45 0 01.45.441l.064 3.47v.097a.441.441 0 00.876.08l.007-.08V3.441a.441.441 0 11.882 0v3.567z" />
    </g>
  </svg>
)

export default Foodie
