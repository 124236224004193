import React from 'react'

const Culture = (props) => (
  <svg width={95} height={24} viewBox="0 0 95 24" {...props}>
    <title>{'Culture'}</title>
    <defs>
      <path id="prefix__a" d="M0 0h72v24H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="prefix__b" fill="#fff">
        <use xlinkHref="#prefix__a" />
      </mask>
      <path
        d="M0 24c2.667-.667 4-2 4-4M0 24c3.333-1.333 6-2 8-2"
        stroke="#A55888"
        mask="url(#prefix__b)"
      />
      <circle fill="#A55888" mask="url(#prefix__b)" cx={4} cy={20} r={1} />
      <circle fill="#A55888" mask="url(#prefix__b)" cx={8} cy={22} r={1} />
      <circle fill="#A55888" mask="url(#prefix__b)" cx={66} cy={7} r={1} />
      <circle fill="#A55888" mask="url(#prefix__b)" cx={64} cy={3} r={1} />
      <circle fill="#A55888" mask="url(#prefix__b)" cx={70} cy={5} r={1} />
      <path
        d="M72 0c-4 1.333-6 3.667-6 7M72 0c-1.333.667-2 2.333-2 5M72 0c-4.667.667-7.333 1.667-8 3"
        stroke="#A55888"
        mask="url(#prefix__b)"
      />
      <text
        mask="url(#prefix__b)"
        fontFamily="Lobster-Regular, Lobster"
        fontSize={15}
        fill="#A55888"
      >
        <tspan x={15.975} y={18}>
          {'culture'}
        </tspan>
      </text>
    </g>
  </svg>
)

export default Culture
