import React from 'react'

const Chill = (props) => (
  <svg width={95} height={24} viewBox="0 0 95 24" {...props}>
    <title>{'Chill'}</title>
    <g fill="none" fillRule="evenodd">
      <text fontFamily="Lobster-Regular, Lobster" fontSize={15} fill="#587FBB">
        <tspan x={12.775} y={18}>
          {'chill'}
        </tspan>
      </text>
      <path stroke="#587FBB" strokeLinecap="round" d="M1 22h15M33 3h15" />
      <circle fill="#587FBB" cx={16} cy={22} r={1} />
      <circle fill="#587FBB" cx={33} cy={3} r={1} />
    </g>
  </svg>
)

export default Chill
