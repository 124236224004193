import React, { useState, useRef, useEffect } from "react";
import { Link, graphql } from "gatsby";

import useAuthState from "Hooks/useAuthState";
import { gql, useQuery, useMutation } from "@apollo/client";
import Moment from "moment";
import styles from "./likes.module.scss";
// SVGs
import BackArrowIcon from "Svg/BackArrowIcon";
import Active from "Svg/Active";
import Adventure from "Svg/Adventure";
import Foodie from "Svg/Foodie";
import Chill from "Svg/Chill";
import Culture from "Svg/Culture";
import Party from "Svg/Party";
import HeartFilled from "Svg/HeartFilled";
import Heart from "Svg/Heart";
import FacebookIcon from "Svg/FacebookIcon";
import InstagramIcon from "Svg/InstagramIcon";
import Loading from "Svg/Loading";
// Components
import SocialIcon from "Components/SocialIcon";
import XupFooter from "Components/XupFooter";
// Hooks
import useWindowWidth from "Hooks/useWindowWidth";
// Utils
import { formatDateStringRelativeToToday } from "Utils/dateTimeFormatting";

import { Object, console } from "window-or-global";

const FETCH_LIKES = gql`
  query FETCH_LIKES($user: String!) {
    experience_likes(where: { user_id: { _eq: $user } }) {
      created_at
      experience_instance_id
      id
      user_id

      user_email {
        status
      }
      experience_instance {
        customId
        activeDate {
          local
        }
        experience {
          _createdAt
          _id
          title
          slug {
            current
          }
          category {
            name
          }
          _createdAt
          image {
            asset {
              url
              source {
                url
              }
            }
          }
        }
      }
    }
    experience_likes_aggregate {
      nodes {
        experience_instance {
          customId
        }
      }
      aggregate {
        count
      }
    }
  }
`;

export default function LikedExperiences(props) {
  const { authState, setAuthState } = useAuthState();
  let width = useWindowWidth();
  let email = useRef(null);
  if (authState.status === "in") {
    email.current = authState.user.email;
  } else {
    email.current = "none";
  }

  const { loading, error, data } = useQuery(FETCH_LIKES, {
    variables: {
      user_id: props.user,
      user: email.current,
    },
    context: { headers: { Authorization: `Bearer ${authState?.token}` } },
  });

  // if above useQuery data is not loaded
  if (loading)
    return (
      <section className={styles.loading}>
        <h1 className={styles.loadingText}>Loading...</h1>
      </section>
    );
  // if data fetch failed
  if (error) return `Error! ${error.message}`;

  const likedExperiencesNumber = data.experience_likes.length;

  function LikedExperiences() {
    const userLikes = data.experience_likes;
    const expLike = data.experience_likes_aggregate.nodes;
    const result2 = [];
    const result = [];

    Object.keys(expLike).forEach(function (key) {
      result2.push(expLike[key].experience_instance[0].customId);
    });

    const counts = {};

    for (var i = 0; i < result2.length; i++) {
      var num = result2[i];
      counts[num] = (counts[num] || 0) + 1;
    }

    Object.keys(userLikes).forEach(function (key) {
      const exp = data.experience_likes[key].experience_instance[0].experience;
      const inst = data.experience_likes[key].experience_instance[0];
      function Category() {
        if (exp.category.name === "Active") {
          return <Active />;
        }
        if (exp.category.name === "Chill") {
          return <Chill />;
        }
        if (exp.category.name === "Foodie") {
          return <Foodie />;
        }
        if (exp.category.name === "Culture") {
          return <Culture />;
        }
        if (exp.category.name === "Adventure") {
          return <Adventure />;
        }
        if (exp.category.name === "Party") {
          return <Party />;
        } else {
        }
      }

      function Number() {
        return <p>{counts[inst.customId]}</p>;
      }
      function Time() {
        const unformatted = inst.activeDate?.local;
        const formatted = new Moment(unformatted);
        return formatted.format("dddd, MMMM D");
      }

      const unformatted = inst.activeDate?.local;
      const formatted = new Moment(unformatted);

      const LinkTime = () => {
        return formatted.format("YYYY-MM-DD");
      };

      if (userLikes.length < 1) {
        result.push(<p>G</p>);
      } else {
        result.push(
          <div className={styles.experience_wrapper}>
            <Link to={`/xperiences/${LinkTime()}/${exp.slug.current}`}>
              <div className={styles.experience}>
                <img className={styles.expImg} src={exp.image.asset.url} alt="img" />
                <section className={styles.info}>
                  <section className={styles.cat_wrapper}>
                    <Category />
                    <div className={styles.likeNumber}>
                      <HeartFilled />
                      <Number />
                    </div>
                  </section>
                  <p>{Time()}</p>
                  <h2>{exp.title}</h2>
                </section>
              </div>
            </Link>
          </div>
        );
      }
    });
    return result;
  }
  function Likes() {
    if (likedExperiencesNumber > 0) {
      return (
        <section className={styles.experiences}>
          <LikedExperiences />
        </section>
      );
    } else {
      return (
        <section className={styles.noLikes}>
          <HeartFilled />
          <h2>
            Click on the heart in an xperience
            <br />
            to add it to this page
          </h2>
        </section>
      );
    }
  }
  return (
    <section className={styles.wrapper}>
      <div className={styles.likedExperiences}>
        <header className={styles.titleWrapper}>
          <Link to="/">
            <BackArrowIcon className={styles.back} />
          </Link>
          <h1>You've Liked {likedExperiencesNumber} xperiences</h1>
        </header>
        <Likes />
      </div>
      <footer className={styles.footer}>
        {width >= 1024 && (
          <section className={styles.social}>
            <SocialIcon link="https://www.instagram.com/xupapp/">
              <InstagramIcon />
            </SocialIcon>
            <SocialIcon link="https://www.facebook.com/whatsxup/">
              <FacebookIcon />
            </SocialIcon>
          </section>
        )}
        {width >= 1024 && (
          <section className={styles.links}>
            <XupFooter />
          </section>
        )}
        {width <= 1024 && (
          <section className={styles.links} style={{ width: "100%", marginTop: "30px" }}>
            <XupFooter />
          </section>
        )}
      </footer>
    </section>
  );
}
