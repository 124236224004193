import React from 'react'

const Active = (props) => (
  <svg width={95} height={24} viewBox="0 0 95 24" {...props}>
    <title>{'Active'}</title>
    <g fill="none" fillRule="evenodd">
      <text fontFamily="Lobster-Regular, Lobster" fontSize={15} fill="#CD5D70">
        <tspan x={14.82} y={16}>
          {'active'}
        </tspan>
      </text>
      <path
        stroke="#CD5D70"
        strokeLinecap="round"
        d="M14.5 1H57v20H9l-1-3-2 5-2-4-1 2H1"
      />
    </g>
  </svg>
)

export default Active
