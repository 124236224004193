import React from 'react'

const Party = (props) => (
  <svg width={95} height={24} viewBox="0 0 95 24" {...props}>
    <title>{'Party'}</title>
    <g fill="none" fillRule="evenodd">
      <text fontFamily="Lobster-Regular, Lobster" fontSize={15} fill="#38383F">
        <tspan x={12.773} y={17}>
          {'party'}
        </tspan>
      </text>
      <path
        stroke="#38383F"
        strokeLinejoin="round"
        d="M57 22h-2v2M0 22h2v2M57 20h-4v4"
      />
      <path stroke="#38383F" strokeLinejoin="round" d="M57 18h-6v6M0 20h4v4" />
      <path stroke="#38383F" strokeLinejoin="round" d="M0 18h6v6M53 0v4h4" />
      <path stroke="#38383F" strokeLinejoin="round" d="M51 0v6h6M4 0v4H0" />
      <path
        stroke="#38383F"
        strokeLinejoin="round"
        d="M6 0v6H0M55 0v2h2M2 0v2H0"
      />
    </g>
  </svg>
)

export default Party
